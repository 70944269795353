import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, Observable, of, switchMap, timer } from 'rxjs';

import {
  Address,
  addressToAddressLines,
  AddressValidationGranularity,
  AddressValidationRequest,
  AddressValidationResponse,
  GeolocationService
} from '../../features/geolocation';

@Injectable({
  providedIn: 'root'
})
export class AddressValidator implements AsyncValidator {
  constructor(private readonly geolocationService: GeolocationService) {}

  createValidate(
    addressFields: Partial<Record<keyof Address, string>>,
    callbackFn?: (control: AbstractControl, geocoderResponse: AddressValidationResponse) => void
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.parent) {
        return of(null);
      }

      const { country, postcode, ...rest } = Object.entries(addressFields).reduce((acc, [key, value]) => {
        return { ...acc, [key]: control.parent?.get(value)?.value };
      }, {}) as Address;

      const request: AddressValidationRequest = {
        address: {
          addressLines: addressToAddressLines(rest)
        }
      };

      return timer(200).pipe(
        switchMap(() => this.geolocationService.validateAddress(request)),
        map((result) => {
          if (result.result.verdict.validationGranularity === AddressValidationGranularity.Premise) {
            if (typeof callbackFn === 'function') {
              callbackFn(control, result);
            }

            return null;
          }

          return { invalidAddress: true };
        })
      );
    };
  }

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    throw new Error('Method not implemented.');
  }
}
