import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, signal, untracked } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormsModule, ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { distinctUntilChanged, merge, Subscription } from 'rxjs';
import { FormService } from '../../services/form/form.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, TranslateModule],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[scError]',
  standalone: true,
  template: `
    @if (message(); as message) {
      {{ message }}
    }
  `
})
export class FormErrorComponent {
  readonly control = input<FormControl>();
  readonly controlName = input<string>();
  readonly customErrors = input<ValidationErrors>();

  readonly message = signal<string | null>(null);

  private formControl = computed(() => {
    return this.control() || this.containerControls[this.controlName()];
  });
  private statusChange: Subscription;

  constructor(
    private readonly controlContainer: ControlContainer,
    private readonly formService: FormService
  ) {
    effect(() => {
      untracked(() => {
        this.initControl();
      });
    });
  }

  private get containerControls(): Record<string, FormControl> {
    return (this.controlContainer.control as FormGroup).controls as Record<string, FormControl>;
  }

  private initControl(): void {
    if (this.statusChange) {
      this.statusChange.unsubscribe();
      this.statusChange = null;
    }

    const control = this.formControl();

    this.statusChange = merge(control.events, control.statusChanges.pipe(distinctUntilChanged())).subscribe(() => {
      let message = null;

      if (control.invalid) {
        message = this.formService.getErrorMessage(control.errors, this.customErrors());
      }

      this.message.set(message);
    });
  }
}
