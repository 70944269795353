import { Address } from './types';

export function addressToAddressLines(address: Partial<Address>): string[] {
  return [[address.street, address.streetNumber], [address.postcode, address.city], address.state, address.country]
    .map((values) => {
      if (Array.isArray(values)) {
        return values.filter((value) => typeof value === 'string' && value.length).join(' ');
      }

      return values;
    })
    .filter((value) => typeof value === 'string' && value.length)
    .filter((value) => typeof value !== 'undefined');
}

export function addressToString(address: Partial<Address>): string {
  return addressToAddressLines(address).join(', ');
}
