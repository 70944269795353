/// <reference types="@types/google.maps" />

export type GeocoderResponse = google.maps.GeocoderResponse;
export type GeocoderResult = google.maps.GeocoderResult;

export interface Address {
  city: string;
  country: string;
  postcode: string;
  state?: string;
  street: string;
  streetNumber?: string;
}

/**
 * Represents the response from the Address Validation API.
 *
 * @see https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#validationresult
 */
export enum AddressValidationGranularity {
  GranularityUnspecified = 'GRANULARITY_UNSPECIFIED',
  SubPremise = 'SUB_PREMISE',
  Premise = 'PREMISE',
  PremiseProximity = 'PREMISE_PROXIMITY',
  Block = 'BLOCK',
  Route = 'ROUTE',
  Other = 'OTHER'
}

export interface AddressValidationRequest {
  address: PostalAddress;
  enableUspsCass?: boolean;
  languageOptions?: {
    returnEnglishLatinAddress: boolean;
  };
  previousResponseId?: string;
  sessionToken?: string;
}

export interface AddressValidationResponse {
  responseId: string;
  result: {
    address: {
      addressComponents: {
        componentName: {
          languageCode: string;
          text: string;
        };
        componentType: string;
        confirmationLevel:
          | 'CONFIRMATION_LEVEL_UNSPECIFIED'
          | 'CONFIRMED'
          | 'UNCONFIRMED_BUT_PLAUSIBLE'
          | 'UNCONFIRMED_AND_SUSPICIOUS';
        inferred: boolean;
        replacement: boolean;
        spellCorrected: boolean;
        unexpected: boolean;
      }[];
      formattedAddress: string;
      missingComponentTypes: string[];
      postalAddress: PostalAddress;
      unconfirmedComponentTypes: string[];
      unresolvedTokens: string[];
    };
    geocode: {
      bounds: {
        high: GeolocationCoords;
        low: GeolocationCoords;
      };
      featureSizeMeters: number;
      location: GeolocationCoords;
      placeId: string;
      placeTypes: string[];
      plusCode: google.maps.places.PlusCode;
    };
    metadata: {
      business: boolean;
      poBox: boolean;
      residential: boolean;
    };
    verdict: {
      addressComplete: boolean;
      geocodeGranularity: AddressValidationGranularity;
      hasInferredComponents: boolean;
      hasReplacedComponents: boolean;
      hasUnconfirmedComponents: boolean;
      inputGranularity: AddressValidationGranularity;
      validationGranularity: AddressValidationGranularity;
    };
  };
}

/**
 * Represents a postal address.
 *
 * @see https://developers.google.com/maps/documentation/address-validation/reference/rest/v1/TopLevel/validateAddress#postaladdress
 */
export interface PostalAddress {
  addressLines: string[];
  administrativeArea?: string;
  languageCode?: string;
  locality?: string;
  postalCode?: string;
  regionCode?: string;
  revision?: number;
  sortingCode?: string;
  sublocality?: string;
}

export interface GeolocationCoords {
  latitude: number;
  longitude: number;
}

export interface IPLocation {
  city: string;
  connection: {
    asn: number;
    isp: string;
  };
  connection_type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  currency: {
    code: string;
    name: string;
    plural: string;
    symbol: string;
    symbol_native: string;
  };
  dma: null;
  ip: string;
  ip_routing_type: string;
  latitude: number;
  location: {
    calling_code: string;
    capital: string;
    country_flag: string;
    country_flag_emoji: string;
    country_flag_emoji_unicode: string;
    geoname_id: number;
    is_eu: boolean;
    languages: {
      code: string;
      name: string;
      native: string;
    }[];
  };
  longitude: number;
  msa: null;
  radius: string;
  region_code: string;
  region_name: string;
  time_zone: {
    code: string;
    current_time: string;
    gmt_offset: number;
    id: string;
    is_daylight_saving: boolean;
  };
  type: string;
  zip: string;
}
